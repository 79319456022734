<template>
  <div>
    <!-- 顶部返回标签 -->
    <van-nav-bar title="作品详情" left-text="返回" left-arrow @click-left="onClickLeft" />
    <!-- 作品海报详情 -->
    <van-row>
      <van-col span="10">
        <div style="position: relative;">
          <van-image width="6.3rem" height="10.0rem" :src="props.image" />
          <!-- 覆盖的 span 标签 -->
          <span class="ad-overlay-text-1">{{ infoItem.sort1 }}</span>
          <span class="ad-overlay-text-2">{{ infoItem.sort2 }}</span>
        </div>
      </van-col>
      <van-col span="14">
        <!-- 设置垂直间距 -->
        <van-row :gutter="[0, 14]" style="padding-top: 0.5rem;">
          <van-col span="22" class="left-align"><span>短剧名称: </span>{{ infoItem.name }}</van-col>
          <van-col span="22" class="left-align"><span>原始素材: </span>{{ infoItem.materials }} /集</van-col>
          <van-col span="22" class="left-align"><span>已经生产: </span>{{ infoItem.upload }} /部</van-col>
          <van-col span="22" class="left-align"><span>剩余作品: </span>{{ infoItem.residue }} /部</van-col>
          <van-col span="22" class="left-align"><span>上架时间: </span>{{ infoItem.timeu }}</van-col>
          <van-col span="22" class="left-align"><span>投稿时间: </span>{{ infoItem.timep }}</van-col>
        </van-row>
      </van-col>
    </van-row>
    <!-- 自动义分割线 -->
    <van-divider dashed :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">↓↓ 作品时长分类
      ↓↓</van-divider>
    <!-- 顶部分类标签 -->
    <van-tabs sticky v-model:active="active" @click-tab="onClickTab">
      <van-tab v-for="(item, index) in minuteTags" :key="index" :title="item.minute + item.unit"></van-tab>
    </van-tabs>
    <!-- 自动义分割线 -->
    <van-divider dashed :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">↓↓ 作品筛选试看
      ↓↓</van-divider>
    <!-- 自动追加与下拉刷新列表 -->
    <van-pull-refresh v-model="refreshList.refreshing" @refresh="onRefresh">
      <van-list v-model:loading="refreshList.loading" :finished="refreshList.finished" finished-text="没有更多了"
        @load="onLoad">
        <!-- 九宫格作品列表 -->
        <van-grid :border="false" :column-num="2" class="van-grid">
          <van-grid-item v-for="(item, index) in worksItems" :key="index">

            <span class="works-title">{{ item.newname }}</span>
            <VideoPlayer :src="item.video" :poster="item.image" playerWidth="10rem" playerHeight="17rem"
              :autoplay="false">
            </VideoPlayer>
            <van-button type="primary" class="works-button" @click="onClickCollectWorks(item)">拿下本作品</van-button>


          </van-grid-item>
        </van-grid>
      </van-list>
    </van-pull-refresh>
    <!-- 垫底空间填充 -->
    <div style="width: 100%; height: 5.8rem;"></div>
  </div>
</template>


<script lang="ts">
import { showToast, showConfirmDialog, showNotify } from 'vant';
import { ref, reactive, defineComponent, PropType, onMounted, onActivated, onDeactivated } from 'vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import { getHost, ossOdlUrl, ossNewUrl, PostLoginInfo, clearCookies } from '@/api/Api';
import { useRouter } from 'vue-router';
import axios from 'axios';


// 定义类型: 分类标签
interface Tab {
  title: string;
}

// 定义类型: 分钟标签
interface MinuteTag {
  minute: string;       // 分钟标签
  unit: string;         // 标签单位
}

// 定义类型: 下拉刷新列表
interface RefreshList {
  refreshing: boolean,  // 重刷状态
  loading: boolean,     // 加载状态
  finished: boolean,    // 完毕状态
  minute: string,       // 分钟标签
  page: number,         // 分页索引
  number: number        // 加载数量
}


// 定义类型: 九宫格列表
interface WorksItem {
  id: string;       // 记录编号
  proof: string;    // 校验标签
  account: string;  // 所属用户
  keymd5: string;   // 短剧标识
  newname: string;  // 作品名称
  duration: string; // 时长秒数
  image: string;    // 作品封面
  video: string;    // 作品预览
  timeu: string;    // 上架时间
  timed: string;    // 下载时间
}

// 定义类型: 短剧信息
interface InfoItem {
  sort: string;      // 分类标签
  sort1: string;     // 分类标签1
  sort2: string;     // 分类标签2
  keymd5: string;    // 短剧标识
  name: string;      // 短剧名称
  materials: string; // 原始素材
  upload: string;    // 已经生产
  residue: string;   // 剩余作品
  download: string;  // 被拿数量
  cover: string;     // 短剧封面
  timeu: string;     // 上架时间
  timep: string;     // 首发时间
  talk: string;      // 必要话题
  aite: string;      // 必要艾特
}


export default defineComponent({
  name: 'ADetails',  // 在这里加上 name 属性
  components: {
    VideoPlayer
  },
  props: {
    title: {
      type: String as PropType<string>,
      default: '页面标题'
    },
    works: {
      type: String as PropType<string>,
      default: '作品名称'
    },
    sort: {
      type: String as PropType<string>,
      default: '剧情分类'
    },
    sort1: {
      type: String as PropType<string>,
      default: '剧情分类'
    },
    sort2: {
      type: String as PropType<string>,
      default: '剧情分类'
    },
    image: {
      type: String as PropType<string>,
      default: '海报链接'
    },
    keyMd5: {
      type: String as PropType<string>,
      default: '唯一Key'
    },
    materials: {
      type: String as PropType<string>,
      default: '原始素材'
    },
    upload: {
      type: String as PropType<string>,
      default: '累计生产'
    },
    residue: {
      type: String as PropType<string>,
      default: '剩余作品'
    },
    timeu: {
      type: String as PropType<string>,
      default: '上架时间'
    }
  },


  setup(props) {
    const active = ref(0);
    // 在 setup 函数内部声明 useRouter
    const routerClass = useRouter();
    // 定义一个响应式的数组，用来存放数据
    const refreshList = ref<RefreshList>({
      refreshing: false,
      loading: false,
      finished: false,
      minute: '分钟标签',
      page: 1,
      number: 20
    });
    const minuteTags = ref<MinuteTag[]>([]);
    const worksItems = ref<WorksItem[]>([]);
    // 声明类型: 登录信息提交
    let postLoginInfo = reactive<PostLoginInfo>({
      nick: '昵称',
      level: '级别',
      phone: '账户',
      token: '令牌',
      timee: '时间',
      state: '状态',
    });
    // 声明类型: 短剧信息
    let infoItem = ref<InfoItem>({
      sort: props.sort,           // 分类标签
      sort1: props.sort1,         // 分类标签1
      sort2: props.sort2,         // 分类标签2
      keymd5: props.keyMd5,       // 短剧标识
      name: props.works,          // 短剧名称
      materials: props.materials, // 原始素材
      upload: props.upload,       // 已经生产
      residue: props.residue,     // 剩余作品
      download: '0',              // 被拿数量
      cover: props.image,         // 短剧封面
      timeu: props.timeu,         // 上架时间
      timep: '查询中...',              // 首发时间
      talk: '',                   // 必要话题
      aite: '',                   // 必要艾特
    });
    const router = useRouter();
    const goBack = () => {
      // 使用浏览器后退
      router.back();
    };


    // 组件生命周期: 组件挂载之后执行的方法
    onMounted(() => {
      console.log("onMounted.vue.组件挂载之后执行的方法");
      onClickInitialization(); // 事件: 初始化
    });


    onActivated(() => {
      console.log('ADetails is activated');
    });

    onDeactivated(() => {
      console.log('ADetails is deactivated');
    });


    /**
     * 事件: 初始化
     */
    function onClickInitialization() {
      // 获取本地存储的登录信息
      postLoginInfo.nick = localStorage.getItem('nick') || '';
      postLoginInfo.level = localStorage.getItem('level') || '';
      postLoginInfo.phone = localStorage.getItem('phone') || '';
      postLoginInfo.token = localStorage.getItem('token') || '';
      postLoginInfo.timee = localStorage.getItem('timee') || '';
      postLoginInfo.state = localStorage.getItem('state') || '';
      // 获取: 作品列表中的分钟标签
      getWorksMinuteTagsr(props.keyMd5)
    }


    // 点击事件: 顶部分类标签
    const onClickTab = (tab: Tab) => {
      showToast(tab.title);
      refreshList.value.page = 1;
      refreshList.value.minute = tab.title.replace('分钟', '');
      // 获取: 作品信息列表
      getWorksInfoList(props.keyMd5, refreshList.value, true);
    };

    // 点击事件: 触底加载更多作品
    const onLoad = () => {
      if (refreshList.value.page == 1) {
        // 将 loading 设置为 false，表示已经加载完毕
        refreshList.value.loading = false
        return;
      }
      console.log('refreshList: ', refreshList);
      // 获取: 作品信息列表
      getWorksInfoList(props.keyMd5, refreshList.value, false);
    };

    // 点击事件: 下拉刷新作品数据
    const onRefresh = () => {
      refreshList.value.page = 1;
      // 获取: 作品信息列表
      getWorksInfoList(props.keyMd5, refreshList.value, true);
      // 将 refreshing 设置为 false，表示非加载中
      refreshList.value.refreshing = false;
    };


    // 点击事件: 拿下本作品
    const onClickCollectWorks = (worksItem: WorksItem) => {
      // console.log("worksItem: " + JSON.stringify(worksItem));
      console.log('nick: ' + postLoginInfo.nick);
      console.log('level: ' + postLoginInfo.level);
      console.log('phone: ' + postLoginInfo.phone);
      console.log('token: ' + postLoginInfo.token);
      console.log('timee: ' + postLoginInfo.timee);
      console.log('state: ' + postLoginInfo.state);
      // 如果登录信息无效 - 则重新登录
      if (postLoginInfo.nick === '' || postLoginInfo.level === '' || postLoginInfo.phone === '' || postLoginInfo.token === '' || postLoginInfo.timee === '' || postLoginInfo.state !== '0') {
        // 使用路由跳转到登录页面
        routerClass.push({ name: 'd_al' });
        return;
      }
      showConfirmDialog({
        title: '温馨提示',
        message:
          `您要拿下[ ${worksItem.newname} ]吗?`,
      })
        .then(() => {
          showToast(`已拿下[ ${worksItem.newname} ]`);
          // 游客、会员、代理商: 收藏作品 (减去1个下载量， 游客下载机会不再重置)
          collectWorks(worksItem);
        })
        .catch(() => {
          console.log("取消");
        });

    };


    // 点击事件: 返回上一页
    const onClickLeft = () => history.back();


    /**
     * 事件: 退出登录
     */
    function onClickExitLogin() {
      // 清空Cookies. 令牌失效需要重新登录
      clearCookies();
      // 使用路由跳转到登录页面
      routerClass.push({ name: 'd_al' });
    }



    /**
     * 获取: 作品列表中的分钟标签
     * 
     * @param keymd5  短剧标识
     */
    async function getWorksMinuteTagsr(keymd5: string) {
      let postMpa = reactive({
        keymd5: keymd5
      })
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=getWorksMinuteTagsr", postMpa, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code != 0) {
          // api.openMessageBoxAlert('提示：', '登录失败，账户或密码不正确!');
          return;
        }
        try {
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            const items: MinuteTag[] = JSON.parse(parsedData.data);
            // 逆序添加到 gridItems 中
            for (let i = items.length - 1; i >= 0; i--) {
              if (items[i].minute === '已经抢空了，请稍候再来...') {
                items[i].unit = '';
              } else {
                items[i].unit = '分钟';
              }
              minuteTags.value.push(items[i]);
            }
          }
          const tab: Tab = {
            title: minuteTags.value[0].minute
          };
          // 点击事件: 顶部分类标签
          onClickTab(tab);
        } catch (error) {
          console.error('Error parsing JSON data:', error);
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }


    /**
     * 获取: 作品信息列表
     * 
     * @param keymd5  短剧标识
     * @param minute  分钟标签
     * @param page    页面索引
     * @param number  加载数量
     * @param isEmpty 是否清空
     */
    async function getWorksInfoList(keymd5: string, refreshList: RefreshList, isEmpty: boolean) {
      let postMpa = reactive({
        keymd5: keymd5,
        minute: refreshList.minute,
        page: refreshList.page,
        number: refreshList.number
      })
      // 将 loading 设置为 true，表示是加载中
      refreshList.loading = true
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=getWorksInfoList", postMpa, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        if (isEmpty) {
          worksItems.value = [];
          // 清空列表数据 - 重置加载完毕状态
          refreshList.finished = false;
        }
        let parsedData = response.data
        if (parsedData.code != 0) {
          if (parsedData.msg === 'MYSQL_SELECT_FAIL' && parsedData.locate === 'TemporarilyNotRecords') {
            console.log('TemporarilyNotRecords');
            // 可能没有更多数据 - 标记为加载完毕状态
            refreshList.finished = true;
            return;
          }
        }
        try {
          // 如果 info 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.info) {
            if (typeof parsedData.info === 'string') {
              infoItem.value = JSON.parse(parsedData.info);
            } else {
              infoItem.value = parsedData.info;
            }
          }
          // 如果 data 存在且是字符串类型，进行二次 JSON 解析
          if (parsedData.data && typeof parsedData.data === 'string') {
            const items: WorksItem[] = JSON.parse(parsedData.data);
            // 逆序添加到 gridItems 中
            for (let i = items.length - 1; i >= 0; i--) {
              items[i].image = items[i].image.replace(ossOdlUrl(), ossNewUrl());
              items[i].video = items[i].video.replace(ossOdlUrl(), ossNewUrl());
              worksItems.value.push(items[i]);
            }
            // 判断是否可能还有剩余数据, 注意只是可能
            if (items.length < refreshList.number) {
              // 可能没有更多数据 - 标记为加载完毕状态
              refreshList.finished = true;
            }
            // 将 loading 设置为 false，表示非加载中
            refreshList.loading = false
            refreshList.page++;
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
          // 加载数据失败 - 标记为加载完毕状态
          refreshList.finished = true;
          // 将 loading 设置为 false，表示非加载中
          refreshList.loading = false
        }
      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }


    /**
     * 游客、会员、代理商: 收藏作品 (减去1个下载量， 游客下载机会不再重置)
     * 
     * @param worksItem  作品项目信息
     */
    async function collectWorks(worksItem: WorksItem) {
      let postJson = reactive({
        token: postLoginInfo.token,
        phone: postLoginInfo.phone,
        proof: worksItem.proof,
        keymd5: worksItem.keymd5,
        id: worksItem.id
      });
      try {
        let response = await axios.post(getHost() + "/php/account.php?func=collectWorks", postJson, {});
        if (response.status != 200) {
          showToast('服务端正在维护, 请稍后重试');
          return;
        }
        let parsedData = response.data
        if (parsedData.code === '-44444') {
          if (parsedData.msg === 'VIPExpiration') {
            showToast('会员到期，请重新办理');
            // 使用路由跳转到: 会员中心 页面
            routerClass.push({ name: 'indexd' });
            return;
          }
        }
        if (parsedData.code !== '0') {
          if (parsedData.msg === 'MYSQL_SELECT_FAIL' && parsedData.locate === 'TokenInvalid') {
            showToast('令牌失效，请重新登录');
            onClickExitLogin(); // 事件: 退出登录
            return;
          }
          if (parsedData.msg === 'MYSQL_SELECT_FAIL' && parsedData.locate === 'FakeCommodities') {
            showToast('已达上限，请明日再来');
            showNotify({ type: 'danger', message: '您今天的拿品数量已经用完了哦' });
            // 使用路由跳转到: 作品管理 页面
            routerClass.push({ name: 'd_ew' });
            return;
          }
          if (parsedData.msg === 'MYSQL_SELECT_FAIL' && parsedData.locate === 'ShortPlayCommodities') {
            showToast('本剧上限，请换部短剧');
            showNotify({ type: 'danger', message: '同一部短剧，每人只能拿下3部作品' });
            return;
          }
          showToast('异常代码: [' + parsedData.code + ']');
          return;
        }
        showToast('成功拿下');
        onClickInitialization(); // 事件: 初始化
        showConfirmDialog({
          title: '温馨提示',
          message:
            `当前作品[${worksItem.newname}]已经成功拿下，并收藏在您的[个人中心]里的[拿下作品栏]中，您可以随时前往您的[拿下作品栏]中管理、预览、下载本作品。\n\n若点击[ 确定 ]按钮，\n则可立即跳转到[拿下作品栏]中下载它。\n\n若您还想再逛一逛，\n请点[ 取消 ]按钮!`,
        })
          .then(() => {
            console.log("确定");
            // 使用路由跳转到: 作品管理 页面
            routerClass.push({ name: 'd_ew' });
          })
          .catch(() => {
            console.log("取消");
          });

      } catch (error) {
        showToast('当前网络错误, 请稍后重试');
      }
    }





    return {
      active,
      props,
      infoItem,
      refreshList,
      minuteTags,
      worksItems,
      goBack,
      onClickCollectWorks,
      onClickLeft,
      onClickTab,
      onLoad,
      onRefresh,
      getWorksMinuteTagsr,
      getWorksInfoList,
    };
  },
});

</script>


<style>
/* 封面的标签 1 */
.ad-overlay-text-1 {
  position: absolute;
  width: 5.8rem;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 文本颜色，可以根据需要修改 */
  color: white;
  /* 文本大小，可以根据需要调整 */
  font-size: 0.3rem;
  /* 半透明背景 */
  background-color: rgba(0, 0, 0, 0.5);
  /* 内边距 */
  padding: 0.1rem 0.1rem;
  /* 圆角边框 */
  border-radius: 0.2rem;
}

/* 封面的标签 2 */
.ad-overlay-text-2 {
  position: absolute;
  width: 5.8rem;
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 文本颜色，可以根据需要修改 */
  color: white;
  /* 文本大小，可以根据需要调整 */
  font-size: 0.3rem;
  /* 半透明背景 */
  background-color: rgba(0, 0, 0, 0.5);
  /* 内边距 */
  padding: 0.1rem 0.1rem;
  /* 圆角边框 */
  border-radius: 0.2rem;
}

/* 作品详情 */
.left-align {
  font-size: 0.8rem;
  text-align: left;
  /* 水平靠左 */
  white-space: nowrap;
  /* 禁止换行 */
  overflow: hidden;
  /* 超出内容隐藏 */
  text-overflow: ellipsis;
  /* 显示省略号 */
}

/* 作品详情 */
.left-align span {
  font-weight: bold;
}

/* 宫格列表 - 必须重置，否则有该死的 margin: 1rem */
.van-grid {
  padding: 0.1rem !important;
  margin: 0rem !important;
}

/* 作品的标题 */
.works-title {
  margin-bottom: 0.3rem;
  font-size: 0.5rem;
  /* 动态宽度 */
  width: 5.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 作品的按钮 */
.works-button {
  margin-top: 0.1rem;
  font-size: 0.5rem;
  /* 动态宽度 */
  width: 10rem;
  height: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>